import React from "react";

const PreviewSomeone = ({ someone = {} }) => {
  return (
    <div className="row">
      <div className="col-3 padding-right-0 padding-right-sm-20 padding-top-10 padding-bottom-10">
        {someone.profile_picture ?
          <img
            className="d-block bg-lighter-blue fixed-width-50 fixed-height-50 rounded-circle"
            src={`${someone.profile_picture}?${Date.now()}`}
          /> :
          <i
            className="fa fa-user-circle lighter-blue"
            style={{ fontSize: "50px" }}
          ></i>}
      </div>
      <div className="col-9 padding-right-0 padding-right-sm-20 padding-top-10 padding-bottom-10">
        <label className="form-label">Name</label>
        <b>{someone.first_name} {someone.last_name} </b>
      </div>
    </div>
  );
};

export default PreviewSomeone
