import React, { useState } from "react";
import { connect } from "react-redux";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import NotificationManager from "react-notifications/lib/NotificationManager";
import {
  poolManagerAddsAssistantManager, poolManagerRemovesAssistantManager,
  poolManagerInvitesUser, poolManagerDisInvitesUser
} from "../../../../../store/actions/moneyPool";
import { getConfirmAlert } from "../../../../../utilities";
import PreviewSomeone from "../../../../utilityComponents/PreviewSomeone";
import { getSomeoneBasicDetails } from "../../../../../store/actions/auth";

const Settings = ({
  user,
  dispatch,
  pool,
  setPool,
  setIsPageLoading
}) => {
  const [previewedAssistantError, setPreviewedAssistantError] = useState("");
  const [previewedAssistantManager, setPreviewedAssistantManager] = useState({});
  const [previewedInviteeError, setPreviewedInviteeError] = useState("");
  const [previewedInvitee, setPreviewedInvitee] = useState({});

  return (
    <div>
      {
        user.id == pool.pool_description.pool_manager_id &&
        <div>
          <div className="horizontal-line margin-bottom-20" />
          <div>
            <b className="font-size-18 font-size-sm-20 dark-blue">
              Admin Settings
            </b>
            <div className="margin-top-20">
              <h3>Collaborators</h3>
              <div className="horizontal-line margin-top-10 margin-bottom-4" />
              <div className="padding-top-10 padding-bottom-10">
                {pool.pool_description.pool_assistant_managers &&
                  pool.pool_description.pool_assistant_managers.length > 0 &&
                  pool.pool_description.pool_assistant_managers.map((manager, index) => {
                    return (
                      <div key={index}>
                        <div className="d-flex padding-top-4 padding-bottom-4">
                          <div className="fixed-width-100 grey">Email</div>
                          <div className="dark-blue">{manager.email}</div>
                        </div>
                        <div className="d-flex padding-top-4 padding-bottom-4">
                          <div className="fixed-width-100 grey">First Name</div>
                          <div className="dark-blue">{manager.first_name}</div>
                        </div>
                        <div className="d-flex padding-top-4 padding-bottom-4">
                          <div className="fixed-width-100 grey">Last Name</div>
                          <div className="dark-blue">{manager.last_name}</div>
                        </div>

                        <button
                          className="btn bg-orange-red border-radius-8 padding-8 max-width-180 font-size-15 margin-top-10"
                          type="button"
                          onClick={() => {
                            getConfirmAlert({
                              title: "Confirm!",
                              message: "Are you sure you want to remove this co-organizer?",
                              onClickYes: () => {
                                setIsPageLoading([true, "...loading"])
                                dispatch(poolManagerRemovesAssistantManager({
                                  pool_id: pool.pool_description.id,
                                  assistant_user_email: manager.email
                                }))
                                  .then(res => {
                                    setIsPageLoading([false])
                                    if (res.error) {
                                      NotificationManager.error(res.error.message, "Error!")
                                    } else {
                                      if (res.success == true) {
                                        NotificationManager.success(res.message, "Success!")
                                        setPool(res.data)
                                      }
                                    }
                                  })
                              },
                              onClickNo: () => null
                            })
                          }}
                        >Remove Organizer</button>
                      </div>
                    )
                  })
                }
              </div>

              <Formik
                initialValues={{
                  pool_id: pool.pool_description.id,
                  assistant_user_email: "",
                }}
                validationSchema={Yup.object({
                  assistant_user_email: Yup.string().email("Must be an email").required("Email is required"),
                })}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                  setIsPageLoading([true, "...loading"])
                  dispatch(poolManagerAddsAssistantManager(values))
                    .then(res => {
                      setSubmitting(false)
                      setIsPageLoading([false])
                      if (res.error) {
                        NotificationManager.error(res.error.message, "Error!")
                        setPreviewedAssistantManager({})
                      } else {
                        if (res.success == true) {
                          NotificationManager.success(res.message, "Success!")
                          setPool(res.data)
                          resetForm()
                          setPreviewedAssistantManager({})
                        }
                      }
                    })
                }}
              >
                {({
                  values,
                  handleSubmit,
                }) => (
                  <Form>
                    <div className="padding-bottom-10">
                      <h4>Invite other users to assist in managing this cyclic money pool</h4>
                      <div className="max-width-400 padding-top-10 stretch-full-sm padding-right-0 padding-right-sm-10">
                        <label className="form-label">Co-organizer's Email</label>
                        <Field
                          className="form-control"
                          placeholder="Add email of the co-organizer"
                          name="assistant_user_email"
                          type="email"
                          onBlur={() => {
                            if (values.assistant_user_email) {
                              setIsPageLoading([true, "...loading"])
                              dispatch(getSomeoneBasicDetails(values.assistant_user_email))
                                .then(res => {
                                  setIsPageLoading([false])
                                  if (res.error) {
                                    setPreviewedAssistantError(res.error.message)
                                    setPreviewedAssistantManager({})
                                  } else {
                                    if (res.success) {
                                      setPreviewedAssistantError("")
                                      setPreviewedAssistantManager(res.data)
                                    }
                                  }
                                })
                            }
                          }}
                        />
                        <ErrorMessage
                          className="error-message"
                          name="assistant_user_email"
                          component="div"
                        />
                        {previewedAssistantError && <span className="red font-size-12">{previewedAssistantError} </span>}
                      </div>

                      {previewedAssistantManager.first_name &&
                        <PreviewSomeone someone={previewedAssistantManager} />
                      }
                      <button
                        className="btn bg-light-normal-blue border-radius-8 padding-8 max-width-140 font-size-15 margin-top-18"
                        type="button"
                        onClick={handleSubmit}
                      >
                        Submit
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      }

      {
        pool.is_pool_manager &&
        <div className="margin-top-20 margin-bottom-20">
          <h3>Pool Invitees</h3>
          <div className="horizontal-line margin-top-10 margin-bottom-4" />

          {pool.pool_description.pool_invitees &&
            pool.pool_description.pool_invitees.length > 0 &&
            pool.pool_description.pool_invitees.map((invitee, index) => {
              return (
                <div key={index} className="padding-top-10 padding-bottom-10">
                  <div className="d-flex padding-top-4 padding-bottom-4">
                    <div className="fixed-width-100 grey">Email</div>
                    <div className="dark-blue">{invitee.email}</div>
                  </div>
                  <div className="d-flex padding-top-4 padding-bottom-4">
                    <div className="fixed-width-100 grey">First Name</div>
                    <div className="dark-blue">{invitee.first_name}</div>
                  </div>
                  <div className="d-flex padding-top-4 padding-bottom-4">
                    <div className="fixed-width-100 grey">Last Name</div>
                    <div className="dark-blue">{invitee.last_name}</div>
                  </div>

                  <button
                    className="btn bg-orange-red border-radius-8 padding-8 max-width-180 font-size-15 margin-top-10"
                    type="button"
                    onClick={() => {
                      getConfirmAlert({
                        title: "Confirm!",
                        message: "Are you sure you want to dinsivite this user?",
                        onClickYes: () => {
                          setIsPageLoading([true, "...loading"])
                          dispatch(poolManagerDisInvitesUser({
                            pool_id: pool.pool_description.id,
                            invitee_email: invitee.email
                          }))
                            .then(res => {
                              setIsPageLoading([false])
                              if (res.error) {
                                NotificationManager.error(res.error.message, "Error!")
                              } else {
                                if (res.success == true) {
                                  NotificationManager.success(res.message, "Success!")
                                  setPool(res.data)
                                }
                              }
                            })
                        },
                        onClickNo: () => null
                      })
                    }}
                  >Disinvite User</button>
                </div>
              )
            })
          }
          <Formik
            initialValues={{
              pool_id: pool.pool_description.id,
              invitee_email: "",
            }}
            validationSchema={Yup.object({
              invitee_email: Yup.string().email("Must be an email").required("Email is required"),
            })}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              setIsPageLoading([true, "...loading"])
              dispatch(poolManagerInvitesUser(values))
                .then(res => {
                  setSubmitting(false)
                  setIsPageLoading([false])
                  if (res.error) {
                    NotificationManager.error(res.error.message, "Error!")
                    setPreviewedInvitee({})
                  } else {
                    if (res.success == true) {
                      NotificationManager.success(res.message, "Success!")
                      setPool(res.data)
                      resetForm()
                      setPreviewedInvitee({})
                    }
                  }
                })
            }}
          >
            {({
              handleSubmit,
              values
            }) => (
              <Form>
                <div className="padding-top-10 padding-bottom-10">
                  <h4>Invite other users to join this cyclic money pool</h4>
                  <div className="max-width-400 padding-top-10 stretch-full-sm padding-right-0 padding-right-sm-10">
                    <label className="form-label">Invitee's Email</label>
                    <Field
                      className="form-control"
                      placeholder="Add email of the invitee"
                      name="invitee_email"
                      type="email"
                      onBlur={() => {
                        if (values.invitee_email) {
                          setIsPageLoading([true, "...loading"])
                          dispatch(getSomeoneBasicDetails(values.invitee_email))
                            .then(res => {
                              setIsPageLoading([false])
                              if (res.error) {
                                setPreviewedInviteeError(res.error.message)
                                setPreviewedInvitee({})
                              } else {
                                if (res.success) {
                                  setPreviewedInviteeError("")
                                  setPreviewedInvitee(res.data)
                                }
                              }
                            })
                        }
                      }}
                    />
                    <ErrorMessage
                      className="error-message"
                      name="invitee_email"
                      component="div"
                    />
                    {previewedInviteeError && <span className="red font-size-12">{previewedInviteeError} </span>}
                  </div>

                  {previewedInvitee.first_name &&
                    <PreviewSomeone someone={previewedInvitee} />
                  }

                  <button
                    className="btn bg-light-normal-blue border-radius-8 padding-8 max-width-140 font-size-15 margin-top-18"
                    type="button"
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      }
    </div>
  );
};

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(Settings);
