import { getUser } from "./auth"
import axiosInstance from "../../helpers/axiosInstance";

// Get pool end date
export const getPoolEndDate = (data) => {
  return async () => {
    return axiosInstance
      .post("/services/money-pools/get-pool-end-date", data)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        return { error: error ? error.response.data : null }
      });
  };
};

//Create a money pool
export const createMoneyPool = (poolDetailValues) => {
  return async () => {
    return axiosInstance
      .post("/services/money-pools/create-new-pool", poolDetailValues)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return { error: error ? error.response.data : null }
      });
  };
};

//Edit money pool details
export const editMoneyPoolDetails = (poolDetailValues) => {
  return async () => {
    return axiosInstance
      .patch("/services/money-pools/edit-pool", poolDetailValues)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return { error: error ? error.response.data : null }
      });
  }
};

//Get all pools for the user
export const getMyPools = (data) => {
  return async () => {
    return axiosInstance.get(
      `/services/money-pools/get-my-pools`,
      { params: data }
    )
      .then(response => {
        return response.data
      })
      .catch(error => {
        return { error: error ? error.response.data : null }
      })
  }
};

//Get pools I manage
export const getPoolsIManage = (data) => {
  return async () => {
    return axiosInstance.get(
      `/services/money-pools/get-pools-i-manage`,
      { params: data }
    )
      .then(response => {
        return response.data
      })
      .catch(error => {
        return { error: error ? error.response.data : null }
      })
  }
};

//Get pools joined
export const getPoolsJoined = (data) => {
  return async () => {
    return axiosInstance.get(
      `/services/money-pools/get-pools-joined`,
      { params: data }
    )
      .then(response => {
        return response.data
      })
      .catch(error => {
        return { error: error ? error.response.data : null }
      })
  }
};

//Get a full money pool
export const getSpecificMoneyPool = (slug) => {
  return async () => {
    return axiosInstance
      .get(`/services/money-pools/get-pool-full-details/${slug}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return { error: error ? error.response.data : null }
      });
  };
};

//Pool member picks a collection date
export const pickCollectionDate = (dateObj) => {
  return async () => {
    return axiosInstance
      .post("/services/money-pools/pick-a-collection-date", dateObj)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return { error: error ? error.response.data : null }
      });
  };
};

//Pool member or manager withdraws a picked collection date
export const removeCollectionDate = (values) => {
  return async () => {
    return axiosInstance
      .delete("/services/money-pools/remove-picked-date", {
        data: values,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return { error: error ? error.response.data : null }
      });
  };
};

//Check if the member has picked their maximum number of dates
export const isMaxDatesPicked = (poolMembers, collectionDates) => {

  return (dispatch, getState) => {
    let isMaxed = false;

    //Loop through collectionDates and filter any date picked by the user
    if (collectionDates.length) {
      const myDetails = poolMembers.filter(
        (member) => member.email == getState().auth.user.email
      )[0];

      if (poolMembers.length > 0) {
        const myPickedDates = collectionDates.filter(
          (dateObj) => dateObj.user_id == getState().auth.user.id
        );

        if (myPickedDates.length >= myDetails.no_of_units) {
          isMaxed = true;
        }
      }

    }
    return isMaxed;
  };
};

//Pay to pool from bank
export const payToPoolFromBank = (data) => {
  return async () => {
    return axiosInstance.post(
      `/services/money-pools/pay-into-pool-from-bank`,
      data
    )
      .then(response => {
        return response.data
      })
      .catch(error => {
        return { error: error ? error.response.data : null }
      })
  }
}

//Pay to pool from wallet
export const payToPoolFromWallet = (data) => {
  return async (dispatch) => {
    return axiosInstance.post(
      `/services/money-pools/pay-into-pool-from-wallet`,
      data
    )
      .then(response => {
        dispatch(getUser())
        return response.data
      })
      .catch(error => {
        return { error: error ? error.response.data : null }
      })
  }
}

//Send date swap request
export const sendDateSwapRequest = (data) => {
  return async () => {
    return axiosInstance.post(
      '/services/money-pools/send-collection-date-swap-request',
      data
    )
      .then(response => {
        return response.data
      })
      .catch(error => {
        return { error: error ? error.response.data : null }
      })
  }
}

//Accept date swap request
export const acceptDateSwapRequest = (data) => {
  return async () => {
    return axiosInstance.post(
      '/services/money-pools/accept-collection-date-swap-request',
      data
    )
      .then(response => {
        return response.data
      })
      .catch(error => {
        return { error: error ? error.response.data : null }
      })
  }
}

//Reject date swap request
export const rejectDateSwapRequest = (data) => {
  return async (dispatch) => {
    return axiosInstance.delete(
      '/services/money-pools/reject-collection-date-swap-request',
      { data }
    )
      .then(response => {
        return response.data
      })
      .catch(error => {
        return { error: error ? error.response.data : null }
      })
  }
}

//Submit pool
export const submitPool = (pool_id) => {
  return async (dispatch) => {
    return axiosInstance.patch(`/services/money-pools/submit-pool/${pool_id}`)
      .then(response => {
        return response.data
      })
      .catch(error => {
        return { error: error ? error.response.data : null }
      })
  }
}

//Uplaod pool picture
export const uploadPoolPicture = (data) => {
  return async (dispatch) => {
    return axiosInstance.post("/services/money-pools/upload-pool-picture", data)
      .then(response => {
        return response.data
      })
      .catch(error => {
        return { error: error ? error.response.data : null }
      })
  }
}

//Get money pool transactions
export const getPoolTransactions = (data) => {
  return async (dispatch) => {
    return axiosInstance.get(`/services/money-pools/get-pool-transactions/${data.poolId}`, {
      params: data.queryParams
    })
      .then(response => {
        return response.data
      })
      .catch(error => {
        return { error: error ? error.response.data : null }
      })
  }
}

//Unlink a receiving bank account from pool
export const unlinkReceivingBankAccountFromPool = (data) => {
  return async (dispatch) => {
    return axiosInstance.post(`/services/money-pools/unlink-receiving-bank-account-from-pool`, data)
      .then(response => {
        return response.data
      })
      .catch(error => {
        return { error: error ? error.response.data : null }
      })
  }
}

//Pool manager adds assistant manager
export const poolManagerAddsAssistantManager = (data) => {
  return async (dispatch) => {
    return axiosInstance.post(`/services/money-pools/pool-manager-adds-assistant-manager`, data)
      .then(response => {
        return response.data
      })
      .catch(error => {
        return { error: error ? error.response.data : null }
      })
  }
}

//Pool manager removes assistant manager
export const poolManagerRemovesAssistantManager = (data) => {
  return async (dispatch) => {
    return axiosInstance.post(`/services/money-pools/pool-manager-removes-assistant-manager`, data)
      .then(response => {
        return response.data
      })
      .catch(error => {
        return { error: error ? error.response.data : null }
      })
  }
}

//Get the list of a member's missed payments
export const getPoolMemberMissedPayments = (params) => {
  return async () => {
    return axiosInstance.get(`/services/money-pools/get-pool-member-missed-payments`, { params })
      .then(response => {
        return response.data
      })
      .catch(error => {
        return { error: error ? error.response.data : null }
      })
  }
}

//Get the pool amount to pay
export const getPoolAmountToPay = (params) => {
  return async () => {
    return axiosInstance.get(`/services/money-pools/get-pool-amount-to-pay`, { params })
      .then(response => {
        return response.data
      })
      .catch(error => {
        return { error: error ? error.response.data : null }
      })
  }
}

//Register pool setup intention
export const registerPoolSetupIntention = (source) => {
  return async () => {
    return axiosInstance.post(`/services/money-pools/register-pool-setup-intention`, { source })
      .then(response => {
        return response.data
      })
      .catch(error => {
        return { error: error ? error.response.data : null }
      })
  }
}

//Clone a money pool
export const cloneMoneyPool = (data) => {
  return async () => {
    return axiosInstance.post(`/services/money-pools/clone-existing-pool`, data)
      .then(response => {
        return response.data
      })
      .catch(error => {
        return { error: error ? error.response.data : null }
      })
  }
}

//Generate sample money pool
export const generateSampleMoneyPool = (data) => {
  return async () => {
    return axiosInstance.post("/services/money-pools/generate-sample-money-pool", data)
      .then(response => {
        return response.data
      })
      .catch(error => {
        return { error: error ? error.response.data : null }
      })
  }
}

//Become a pool guarantor
export const becomeAPoolGuarantor = (data) => {
  return async () => {
    return axiosInstance.post("/services/money-pools/become-a-pool-guarantor", data)
      .then(response => {
        return response.data
      })
      .catch(error => {
        return { error: error ? error.response.data : null }
      })
  }
}


//Get an approved pool guarantor by email
export const getAnApprovedPoolGuarantor = (email) => {
  return async () => {
    return axiosInstance.get(`/services/money-pools/get-an-approved-pool-guarantor?guarantor_email=${email}`)
      .then(response => {
        return response.data
      })
      .catch(error => {
        return { error: error ? error.response.data : null }
      })
  }
}


//For an approved guarantor to guarantee another user
export const guaranteePoolMember = (data) => {
  return async () => {
    return axiosInstance.post("/services/money-pools/guarantee-a-pool-member", data)
      .then(response => {
        return response.data
      })
      .catch(error => {
        return { error: error ? error.response.data : null }
      })
  }
}

//For a pool member to request a guarantee from an approved guarantor
export const requestAPoolGuarantee = (data) => {
  return async () => {
    return axiosInstance.post("/services/money-pools/request-a-guarantee", data)
      .then(response => {
        return response.data
      })
      .catch(error => {
        return { error: error ? error.response.data : null }
      })
  }
}


//For a user to get the members they have guaranteed
export const getMyGuaranteedMembers = (params) => {
  return async () => {
    return axiosInstance.get("/services/money-pools/get-pool-members-i-guaranteed", { params })
      .then(response => {
        return response.data
      })
      .catch(error => {
        return { error: error ? error.response.data : null }
      })
  }
}


//For a user to get their guarators
export const getMyPoolGuarantors = (params) => {
  return async () => {
    return axiosInstance.get("/services/money-pools/get-my-pool-guarantors", { params })
      .then(response => {
        return response.data
      })
      .catch(error => {
        return { error: error ? error.response.data : null }
      })
  }
}

//For a user to get their approved open guarantees
export const getMyApprovedOpenGuarantees = (params) => {
  return async () => {
    return axiosInstance.get("/services/money-pools/get-my-pending-redeem-guarantees", { params })
      .then(response => {
        return response.data
      })
      .catch(error => {
        return { error: error ? error.response.data : null }
      })
  }
}

//Get public pools
export const getPublicPools = (data) => {
  return async () => {
    return axiosInstance.get(
      `/services/money-pools/get-public-money-pools`,
      { params: data }
    )
      .then(response => {
        return response.data
      })
      .catch(error => {
        return { error: error ? error.response.data : null }
      })
  }
};

//For authenticated member of the public to get full public money pool
export const getSpecificOpenPublicMoneyPool = (slug) => {
  return async () => {
    return axiosInstance
      .get(`/services/money-pools/get-open-public-pool/${slug}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return { error: error ? error.response.data : null }
      });
  };
};

//A pool guarantor to change the status of the guarantee
export const disablePoolGuarantee = (guaranteeId) => {
  return async () => {
    return axiosInstance
      .patch(`/services/money-pools/disable-guarantee/${guaranteeId}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return { error: error ? error.response.data : null }
      });
  };
};

//A pool guarantor changes the guarantee limit
export const changePoolGuaranteeLimit = (data) => {
  return async () => {
    return axiosInstance
      .patch("/services/money-pools/change-pool-guarantee-limit", data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return { error: error ? error.response.data : null }
      });
  };
};

//For a pool manager to change the pool audience between public and private
export const managerChangePoolAudience = (data) => {
  return async () => {
    return axiosInstance
      .post("/services/money-pools/manager-change-pool-audience", data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return { error: error ? error.response.data : null }
      });
  };
};


//Pool manager invites a user
export const poolManagerInvitesUser = (data) => {
  return async () => {
    return axiosInstance.post(`/services/money-pools/manager-invites-user-to-pool`, data)
      .then(response => {
        return response.data
      })
      .catch(error => {
        return { error: error.response.data }
      })
  }
}


//Pool manager dis-invites an invitee from pool
export const poolManagerDisInvitesUser = (data) => {
  return async () => {
    return axiosInstance.post("/services/money-pools/manager-dis-invites-user-from-pool", data)
      .then(response => {
        return response.data
      })
      .catch(error => {
        return { error: error.response.data }
      })
  }
}